body {
  font-family: "azo-sans-web", sans-serif !important;
}

.main-section {
  min-height: calc(100vh - 480px);
}

.no-underline {
  text-decoration: none !important;
}

.no-underline-hover:hover {
  text-decoration: none !important;
}

.underline {
  text-decoration: underline !important;
}

.nowrap {
  white-space: nowrap;
}

.form-radio-circle {
  margin-right: 5px;
}

.form-error {
  text-align: left;
  color: red;
  font-size: smaller;
}

.red-text {
  color: red !important;
}

.left-align-text {
  text-align: left;
}

// International Phone Number Input

.PhoneInputInput {
  height: 100%;
  padding-left: 10px;
}

/* Datepicker Styles */

.dm-datepicker-date-available {
  color: blue;
}

.dm-datepicker-date-booked-available-start {
  background-image: -webkit-linear-gradient(135deg, #999 50%, #FFFFFF 50%);
  border-radius: 0.3rem;
}

.dm-datepicker-date-booked-available-start-wider {
  background-image: -webkit-linear-gradient(120deg, #999 50%, #FFFFFF 50%);
}

.dm-datepicker-date-booked-available-end {
  background-image: -webkit-linear-gradient(315deg, #999 50%, #FFFFFF 50%);
  border-radius: 0.3rem;
}

.dm-datepicker-date-booked-available-end-wider {
  background-image: -webkit-linear-gradient(300deg, #999 50%, #FFFFFF 50%);
}

.dm-datepicker-date-booked-unavailable {
  background-color: #999;
  border-radius: 0.3rem;
}

.dm-datepicker-date-booked-unavailable:hover {
  background-color: #999;
}

.react-datepicker__day--in-range {
  background-color: #bad9f1 !important;
  color: white !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #216ba5 !important;
  color: white !important;
}

.react-datepicker__children-container {
  width:100%;
  margin:0px;
  padding:0.5rem;
}

.dm-datepicker-clear-submit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width:100%;
  min-width: 240px;
  place-content: center;
}

.datepicker-date-booked-hash {
  background: repeating-linear-gradient(135deg,
      #CCC,
      #EEE 2px,
      white 2px,
      white 4px);
  text-align: center;
}

.react-datepicker__day--disabled,
.react-datepicker__day--excluded {
  color: #CCC;
  border-radius: 0.3rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar {
  width: 0px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #55555530;
}

/*
Gray Text = can't click and not available for Check In or Check Out   (OK)
Black Text = Available for Check In or Check Out                      (OK)
Gray Hashed Square (diagonal lines covering box) = Booked Dates       (??)

Prior to "Check In" Date Selected
  All Booked Dates are Shown as  Gray Hashed Background/unclickable
  All Available Check In Dates are Black Text and clickable (maybe with a mouseover animation)
  All Unavailable Check In dates are Gray Text/unclickable

AFTER "Check In" Date is Selected
  All Dates prior to Check In Date are Gray Text/unclickable
  All Dates after Check In Date that meet season constraints (cta/ctd) and minimum stay constraints are Black Text
  A Check In date that is a Booked Date that was Gray Hashed Background is now Black Text if it is available for Check Out (because as described earlier, you can check-out on someone's check-in date, or check-in on someone's check-out date, if the rest of the reservation is otherwise available)
  All Other future Booked Dates Remain Gray Hashed Background
*/

.datepicker-element-new {
  border: 2px solid red;
  margin: 20px;
}

.react-datepicker {
  border-color: #0f223830;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 8px;
}

.react-datepicker__header {
  background-color: #0f2238;
  border-radius: 0px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: white;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: calc(100vw / 32);
}

.react-datepicker__navigation-icon {
  font-size: 14px;
  top: 0px;
}

.react-datepicker__month-container {
  width: 50%;
}

@media only screen and (min-width: 1000px) {

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: calc(100vw / 28);
    line-height: 2rem;
    max-width: 58px;
  }
}

@media only screen and (max-width: 767px) {

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: calc(100vw / 8.5);
    line-height: 2rem;
  }

  .react-datepicker__month-container {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: calc(100vw / 9.2);
    line-height: 1.8rem;
  }

  .react-datepicker__month-container {
    width: 100%;
  }
}

.react-datepicker-popper .react-datepicker__day-name,
.react-datepicker-popper .react-datepicker__day,
.react-datepicker-popper .react-datepicker__time-name {
  max-width: 30px;
}

.react-datepicker-popper .react-datepicker__month-container {
  width: 100%;
}

.mobile-menu-date-picker .react-datepicker-popper,
.mobile-menu-date-picker .react-datepicker {
  margin-top: -5px !important;
}

.mobile-menu-date-picker .react-datepicker__triangle {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  height: 0px !important;
}


/* Other */

.dm-button {
  border-radius: 38px !important;
  padding: 30px 30px !important;
  border: solid 2px #e95037 !important;
  font-weight: 500 !important;
  background-color: white !important;
  color: #e95037 !important;
}

.dm-button:hover {
  background-color: #e9503720 !important;
  text-decoration: none !important;
}

.dm-button-mobile {
  border-color: #e95037 !important;
  font-weight: 500 !important;
  background-color: white !important;
  color: #e95037 !important;
}

.dm-button-mobile:hover {
  background-color: #e9503720 !important;
  text-decoration: none !important;
}

.dm-button-orange-pill {
  border-radius: 38px !important;
  padding: 30px 25px;
  background-color: #e95037 !important;
  font-weight: 500 !important;
  color: white !important;
  border: solid 2px #e95037;
}

.dm-button-orange-pill:hover {
  background-color: #c43d28 !important;
  text-decoration: none !important;
  border: solid 2px #c43d28;
}

.dm-button-orange-pill-small {
  border-radius: 38px !important;
  padding: 0px 30px !important;
  background-color: #e95037 !important;
  font-weight: 500 !important;
  color: white !important;
  border: solid 1px #e95037;
  font-size: 14px !important;
}

.dm-button-blue-pill {
  border-radius: 38px !important;
  padding: 30px 25px !important;
  background-color: #0f2238 !important;
  font-weight: 500 !important;
  color: white !important;
}

.dm-button-blue-pill:hover {
  background-color: white !important;
  text-decoration: none !important;
}

.dm-button-navy-pill {
  border-radius: 38px !important;
  padding: 30px 25px !important;
  background-color: #0f2238 !important;
  font-weight: 500 !important;
  color: white !important;
}

.dm-button-navy-pill:hover {
  background-color: #091420 !important;
  text-decoration: none !important;
}

.dm-button-navy-pill-small {
  background-color: #0f2238 !important;
  color: white !important;
  font-weight: 400 !important;
  border-radius: 6px !important;
}

.dm-button-navy-pill-small:hover {
  background-color: #091420 !important;
}

.dm-button-orange-solid-small {
  background-color: #e95037 !important;
  color: white !important;
  font-weight: 400 !important;
  border-radius: 6px !important;
}

.dm-button-orange-solid-small:hover {
  background-color: #c43d28 !important;
}

.dm-button-orange-outline {
  border-radius: 6px !important;
  padding: 10px 40px !important;
  border: solid 1px #e95037 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #e95037;
  background-color: white !important;
}

.dm-button-orange-outline:hover {
  background-color: #e9503720 !important;
  text-decoration: none !important;
}

.dm-button-navy-outline {
  border-radius: 38px !important;
  padding: 30px 25px !important;
  border: solid 2px #0f2238 !important;
  color: #0f2238;
  background-color: white !important;
  font-weight: 500 !important;
}

.dm-button-navy-outline:hover {
  background-color: #0f223820 !important;
  text-decoration: none !important;
}

.dm-button-orange-solid {
  border-radius: 6px !important;
  padding: 10px 40px !important;
  border: solid 1px #e95037 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  background-color: #e95037 !important;
  color: white !important;
}

.dm-button-orange-solid:hover {
  background-color: #c43d28 !important;
  border: solid 1px #c43d28 !important;
  text-decoration: none !important;
}

.dm-button-gray-pill {
  border-radius: 38px !important;
  padding: 30px 30px;
  border: solid 2px lightgray !important;
  color: #0f2238;
  background-color: lightgray !important;
  font-weight: 500 !important;
}

.share-trip-reservation-widget .chakra-button__icon svg {
  width: 22px;
  height: 22px;
}

.trip-details-reservation-widget .chakra-button__icon svg {
  width: 22px;
  height: 22px;
}

.explore-homes-past-reservation-widget .chakra-button__icon svg {
  width: 22px;
  height: 22px;
}

.input-blue-button::file-selector-button {
  background-color: #0f2238;
  color: white;
  padding: 5px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 15px;
}

div:has(.chakra-menu__menu-list) {
  inset: 0px 5px auto auto !important;
}

.account-button {
  color: white !important;
  text-decoration: none !important;
}

.guest-form {
  width: 100%;
}

.slick-dots {
  bottom: 50px;
}

@media screen and (max-width: 768px) {
  .slick-dots {
    display: none !important;
  }
}

.slick-dots li button:before {
  font-size: 20px;
  line-height: 20px;
}

.mini-carousel .slick-prev {
  width: 36px;
  height: 36px;
  left: -36px;
}

.mini-carousel .slick-next {
  width: 36px;
  height: 36px;
  right: -36px;
}

.mini-carousel .slick-next:hover,
.mini-carousel .slick-prev:hover {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
}

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.star-rating {
  width: 36px;
  height: 36px;
  cursor: pointer;
  font-size: 300%;
  color: orange;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.star-container {
  width: 250px;
  margin: 0 auto;
}

.container-hidden {
  visibility: hidden;
  height: 0px !important;
}

.cl-formButtonPrimary {
  background-color: #0f2238;
}

.cl-formButtonPrimary:hover {
  background-color: #173353;
}

.init-signin-modal-content {
  padding: 150px 180px 100px 180px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 115%;
  /* or 23px */
  text-align: center;
}

.right-hand-panel {
  background: #ffffff;
  box-shadow: 5px 0px 25px rgba(0, 0, 0, 0.25);
  padding: 200px 35px 0px 35px;
  align-items: center;
}

.slider-icon-wrapper {
  border: solid 1px #4a4a4a;
  border-radius: 50%;
  margin: 0px 5px;
  padding: 8px;
  height: 36px;
  width: 36px;
}

.slider-icon-wrapper:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.slider-icon-left {
  padding-right: 3px;
}

.slider-icon-right {
  padding-left: 3px;
}

span[type="prev"] {
  position: float;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.image-transition {
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
}

#calendar-input {
  width: 100%;
  max-width: 100vw;
  border: solid 1px #CCC;
  padding: 10px;
  text-align: center;
  font-size: 16px;
}

// Admin Table

/* Add this to your CSS file */
.admin-table {
  width: 95vw !important;
  max-width: 95vw;
  border-collapse: collapse;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
}

.admin-table thead {
  background-color: #4a4a4a;
  color: #ffffff;
}

.admin-table thead th {
  padding: 10px;
  text-align: left;
  font-weight: 600;
  word-wrap: break-word;
}

.admin-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.admin-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.admin-table tbody tr:hover {
  background-color: #dddddd;
}

.admin-table tbody td {
  padding: 10px;
  text-align: left;
  word-wrap: break-word;
  max-width: 160px;
}

.admin-table tbody td:last-child {
  text-align: right;
}

td.react-bootstrap-editing-cell {
  width: 300px;
  height: 100%;
}

.hidden {
  visibility: hidden;
  height: 0px;
}

.gallery-carousel-circle-active {
  //background: white;
  background: black;
}

.gallery-carousel-circle-neighbor {
  //background: rgba(255, 255, 255, 0.24);
  background: darkgrey;
}

.gallery-carousel-circle-none {
  display: none !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container>input {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 767px) {

  .react-datepicker__input-container>input {
    border: solid 1px #0f2238;
    border-radius: 6px;
  }

  .react-datepicker__input-container ::placeholder {
    padding-left: 5px;
    color: black;
  }

  .chakra-numberinput__field::placeholder {
    color: black !important;
  }
}

.react-datepicker__input-container>input {
  padding-left: 10px;
  padding-right: 5px;
}

.react-datepicker__close-icon::after {
  background-color: #e95037;
}

.select-town-placeholder {
  color: #E2E8F0;
}

.stripe-form-container {
  width: 100%;
  max-width: 100vw;
  min-height: 100px;
}

/// Swiper Slide for Listing Gallery Mobile
.swiper-slide {
  height: auto !important;
}

@media screen and (max-width: 768px) {
  .hs-drag-handle {
    display: none !important;
  }
}

.react-datepicker__day--today {
  background: transparent !important;
}

.custom-datepicker-scroll {
  max-height: 350px;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #1d5d90 !important;
}